import React, { useState } from "react";

import { useId } from "react-aria";

import { formatDate, toReadableDate } from "../../../../utils/DateHelpers";
import NewInput from "../NewInput";
import Modal from "./Modal";

const DatePicker = ({
    value,
    onChange,
    label = "Datum",
    locale = "de",
    maxDate = undefined,
    forceError = false,
    name,
    id = null,
    ...props
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const onClick = () => {
        setIsModalVisible(true);
    };

    const handleModal = (event) => {
        if ((event && event.target.classList.contains("backdrop")) || !event) {
            setIsModalVisible(!isModalVisible);
        }
    };

    const onDateChanged = (date) => {
        onChange(formatDate(date));
        setIsModalVisible(false);
    };

    const fallbackId = useId();
    id ??= name ?? fallbackId;

    return (
        <>
            <NewInput
                placeholder={label}
                onClick={onClick}
                value={value ? toReadableDate(value) : ""}
                readOnly
                forceError={forceError}
                {...{ id, name }}
            />
            <Modal
                isActive={isModalVisible}
                handleClose={handleModal}
                onChange={onDateChanged}
                locale={locale}
                maxDate={maxDate}
                value={value}
                {...props}
            />
        </>
    );
};

export default DatePicker;
