/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { forwardRef, useCallback } from "react";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import loadable from "@loadable/component";

import Backdrop from "../../../Backdrop";
import { BlurOther } from "../../../Blur";
import ModalPortal from "../../../Modal/Portal";
import NewInput, { inputStyles } from "../../NewInput";

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    ${({ theme }) => theme.paddings.containerOuter.left}
    ${({ theme }) => theme.paddings.containerOuter.right}
`;

const ModalContentContainer = styled.div`
    z-index: 23;
    border-radius: 10px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    ${({ theme }) => theme.breakpoints.from.large.css`
        margin-top: 150px;
    `}
`;

const Modal = ({
    isActive = null,
    handleClose,
    value,
    onChange,
    label = "Datum",
    locale = "de",
    maxDate = undefined,
    ...props
}) => {
    const open = !!isActive;
    const ReactDatePicker = loadable(() => import("../wrapper"));

    const theme = useTheme();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const DatePickerInput = useCallback(
        // eslint-disable-next-line react/display-name
        forwardRef(({ value, onClick }, ref) => (
            <NewInput
                placeholder={label}
                innerRef={ref}
                onClick={onClick}
                value={value}
                readOnly
                css={css`
                    width: 243px;
                `}
            />
        )),
        [label]
    );

    return (
        open && (
            <>
                <BlurOther blur={["Page"]} />
                <ModalPortal>
                    <Backdrop
                        {...{ open }}
                        onClick={handleClose}
                        className="backdrop"
                    >
                        <ModalContainer className="backdrop">
                            <ModalContentContainer>
                                <ReactDatePicker
                                    locale={locale}
                                    dateFormat="dd.MM.yyyy"
                                    open={true}
                                    showNow={false}
                                    selected={
                                        value !== "" ? new Date(value) : value
                                    }
                                    onChange={onChange}
                                    customInput={<DatePickerInput />}
                                    {...(maxDate ? { maxDate } : {})}
                                    css={css`
                                        ${inputStyles({ theme })}
                                    `}
                                    {...props}
                                />
                            </ModalContentContainer>
                        </ModalContainer>
                    </Backdrop>
                </ModalPortal>
            </>
        )
    );
};

export default Modal;
